import React from 'react'
import { Link } from 'react-router-dom'

import { Box, IconButton, Container, Grid, Typography } from '@mui/material'

import logo from '../../assets/img/kin-logo-blanco.png'

import navItems from '../../routes/pages'
import socials from '../../config/socials'

const Footer = (props) => {
    return (
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#083472' }}>
            <Container maxWidth="md">
                <Grid container rowSpacing={2} columnSpacing={5}>
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column' }} align='center'>
                        <Typography variant="h6" color="text.primary" gutterBottom align='center'>
                            Información
                        </Typography>

                        { navItems.map(item => 
                            <Link key={item.label} to={item.path} 
                                color='text.secondary' 
                                style={{ 
                                    textDecoration: 'none',
                                    color: 'rgba(255, 255, 255, 0.67)',
                                }} >

                                {item.label}
                            </Link>
                        )}
                    </Grid>
                    
                    <Grid item xs={12} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Typography variant="body2" color="text.secondary" align="center" {...props} mb={2}>
                            <img src={logo} width={128} alt='Kin Solution Systems Logo' />
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Legal
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            123 Main Street, Anytown, USA
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Email: info@example.com
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Phone: +1 234 567 8901
                        </Typography>
                    </Grid> */}

                    <Grid item xs={12} sm={4} align='center'>
                        <Typography variant="h6" color="text.primary" gutterBottom align='center'>
                            Contacto
                        </Typography>

                        { socials.map(item => 
                            <IconButton key={item.url} component={Link} to={item.url} color="inherit">
                                {item.icon}
                            </IconButton>
                        ) }
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <Typography variant="body2" color="text.secondary" align="center" {...props}>
                        {'Copyright © '}
                        <Link color="inherit" to="/" 
                            style={{ 
                                textDecoration: 'none',
                                color: 'rgba(255, 255, 255, 0.67)',
                            }}>

                            KIN Solution Systems
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer