import android from '../../../assets/img/technologies/Android_Icon.png'
import arduino from '../../../assets/img/technologies/Arduino_Icon.png'
import cpp from '../../../assets/img/technologies/C++_Icon.png'
import css from '../../../assets/img/technologies/CSS3_Icon.png'
import csharp from '../../../assets/img/technologies/CSharp_Icon.png'
import c from '../../../assets/img/technologies/C_Icon.png'
import django from '../../../assets/img/technologies/Django_Icon.png'
import docker from '../../../assets/img/technologies/Docker_Icon.png'
import electron from '../../../assets/img/technologies/ElectronJS_Icon.png'
import firebase from '../../../assets/img/technologies/Firebase_Icon.png'
import html from '../../../assets/img/technologies/HTML5_Icon.png'
import javascript from '../../../assets/img/technologies/JavaScript_Icon.png'
import java from '../../../assets/img/technologies/Java_Icon.png'
import jetpack from '../../../assets/img/technologies/JetpackCompose_Icon.png'
import kotlin from '../../../assets/img/technologies/Kotlin_Icon.png'
import linux from '../../../assets/img/technologies/Linux_Icon.png'
import mariadb from '../../../assets/img/technologies/MariaDB_Icon.png'
import mongodb from '../../../assets/img/technologies/Mongo_Icon.png'
import mysql from '../../../assets/img/technologies/MySQL_Icon.png'
import nginx from '../../../assets/img/technologies/NginX_Icon.png'
import nodejs from '../../../assets/img/technologies/NodeJS_Icon.png'
import php from '../../../assets/img/technologies/Php_Icon.png'
import python from '../../../assets/img/technologies/Python_Icon.png'
import reacticon from '../../../assets/img/technologies/React_Icon.png'
import swiftui from '../../../assets/img/technologies/SwiftUI_Icon.png'
import swift from '../../../assets/img/technologies/Swift_Icon.png'
import unity from '../../../assets/img/technologies/Unity_Icon.png'

const icons = [
    {
        alt: 'Android Icon', 
        image: android
    },
    {
        alt: 'Arduino Icon',
        image: arduino
    },
    {
        alt: 'C++ Icon',
        image: cpp
    },
    {
        alt: 'CSS Icon',
        image: css
    },
    {
        alt: 'C# Icon',
        image: csharp
    },
    {
        alt: 'C Icon',
        image: c
    },
    {
        alt: 'Django Icon',
        image: django
    },
    {
        alt: 'Docker Icon',
        image: docker
    },
    {
        alt: 'ElectronJS Icon',
        image: electron
    },
    {
        alt: 'Firebase Icon',
        image: firebase
    },
    {
        alt: 'HTML Icon',
        image: html
    },
    {
        alt: 'JavaScript Icon',
        image: javascript
    },
    {
        alt: 'Java Icon',
        image: java
    },
    {
        alt: 'Jetpack Compose Icon',
        image: jetpack
    },
    {
        alt: 'Kotlin Icon',
        image: kotlin
    },
    {
        alt: 'Linux Icon',
        image: linux
    },
    {
        alt: 'MariaDB Icon',
        image: mariadb
    },
    {
        alt: 'MongoDB Icon',
        image: mongodb
    },
    {
        alt: 'MySQL Icon',
        image: mysql
    },
    {
        alt: 'Nginx Icon',
        image: nginx
    },
    {
        alt: 'NodeJS Icon',
        image: nodejs
    },
    {
        alt: 'Php Icon',
        image: php
    },
    {
        alt: 'Python Icon',
        image: python
    },
    {
        alt: 'React Icon',
        image: reacticon
    },
    {
        alt: 'SwiftUI Icon',
        image: swiftui
    },
    {
        alt: 'Swift Icon',
        image: swift
    },
    {
        alt: 'Unity Icon',
        image: unity
    },
]

export default icons