import React from 'react'
import { Link } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { Box, IconButton, Container, Typography } from '@mui/material'

import kinLogo from '../../../assets/img/kin-logo-blanco.png'
import hero from '../../../assets/img/team1.png'

import socials from '../../../config/socials'

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '80vh',
        minHeight: 500,
        maxHeight: 1300,
    },
}))

const Background = styled(Box)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
})

function ProductHeroLayout(props) {
    const { sxBackground, children } = props

    return (
        <ProductHeroLayoutRoot>
            <Container sx={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
                <img src={kinLogo} alt="Kin Solution Systems Logo" width="147" height="80" />
                {children}
                <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: 'common.black', opacity: 0.5, zIndex: -1, }} />
                <Background sx={sxBackground} />
            </Container>
        </ProductHeroLayoutRoot>
    )
}

ProductHeroLayout.propTypes = {
    children: PropTypes.node,
    sxBackground: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
}

const Hero = () => {
    return (
        <ProductHeroLayout sxBackground={{ backgroundImage: `url(${hero})`, backgroundColor: '#32A9DD', backgroundPosition: 'center', }} >
            {/* Increase the network loading priority of the background image. */}
            <img style={{ display: 'none' }} src={hero} alt="increase priority" />

            <Typography color="inherit" align="center" variant="h1" marked="center" sx={{ maxWidth: { xs: '100%', sm: '80%' } }}>
                Transformando desafíos en oportunidades, creando soluciones sin fronteras.
            </Typography>
            <Typography color="inherit" align="center" variant="h2" marked="center" my={4} sx={{ maxWidth: { xs: '90%', sm: '70%' } }}>
                Ofrecemos soluciones completas y personalizadas para cubrir las necesidades específicas de tu empresa
            </Typography>
            
            <Typography color="inherit" align="center" variant="body1" marked="center" my={0} >
                Contáctanos
                <br />
                { socials.map(item => 
                    <IconButton key={item.url} component={Link} to={item.url} color="inherit">
                        {item.icon}
                    </IconButton>
                ) } 
            </Typography>

            {/* <Typography variant="body1" color="inherit" sx={{ mt: 2 }}>
                Discover the experience
            </Typography> */}
        </ProductHeroLayout>
    )
}

export default Hero