import '../assets/fonts/fonts.css'

const kinTheme = {
    palette: {
        mode: 'light',
        
        primary: {
            main: '#083472',
            light: '#0B0C10',
            dark: '#0B0C10',
            contrastText: '#FFFFFF'
        },

        secondary: {
            main: '#32A9DD',
            light: '#1F2833',
            dark: '#1F2833',
            contrastText: '#66FCF1'
        },

        tertiary: {
            main: '#C5C6C7',
            light: '#C5C6C7',
            dark: '#C5C6C7',
            contrastText: '#45A29E'
        },

        background: {
            paper: '#1F2833',
            default: '#C5C6C7'
        },

        text: {
            primary: 'rgba(255, 255, 255, 0.87)',
            secondary: 'rgba(255, 255, 255, 0.67)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        }
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: "'EurostileBold', 'NordicAlternativeRegular', 'OkojoProBold', 'Roboto'",
        h1: {
            fontFamily: "EurostileBold",
            fontWeight: 300,
            fontSize: "3rem",
            lineHeifht: 1.167,
            letterSpacing: "-0.01562em"
        },
        h2: {
            fontFamily: "EurostileBold",
            fontWeight: 300,
            fontSize: "2rem",
            lineHeifht: 1.2,
            letterSpacing: "-0.00833em"
        },
    }
}

export default kinTheme