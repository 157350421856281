import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'

const socials = [
    {
        url: "https://www.facebook.com/KinSolutionSystems/",
        name: '@KinSolutionSystems',
        icon: <Facebook />,
        ariaLabel: 'Go to Kin Solution Systems\'s Facebook'
    },
    {
        url: "https://www.instagram.com/kinsolutionsystems/",
        name: '@kinsolutionsystems',
        icon: <Instagram />,
        ariaLabel: 'Go to Kin Solution Systems\'s Instagram'
    },
    {
        url: "https://www.linkedin.com/in/kin-solution-systems/",
        name: 'Kin Solution systems',
        icon: <LinkedIn />,
        ariaLabel: 'Go to Kin Solution Systems\'s LinkedIn'
    },
]

export default socials