/**
 * Pages
 */
const navItems = [
    {
        label: 'Inicio',
        path: '/'
    },
    {
        label: 'Portafolio',
        path: '/portafolio'
    },
    // {
    //     label: 'Contáctanos',
    //     path: '/contactanos'
    // },
]

export default navItems