import React from 'react'
import Hero from '../components/pages/Home/Hero'

import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { register } from 'swiper/element/bundle'

import icons from '../components/pages/Home/TecnologyIcons'

register();

const Home = () => {
    const theme = useTheme()

    const isXM = useMediaQuery(theme.breakpoints.only('xs'))
    const isSM = useMediaQuery(theme.breakpoints.only('sm'))
    const isMD = useMediaQuery(theme.breakpoints.only('md'))
    const greaterThanSM  = useMediaQuery(theme.breakpoints.up('sm'))

    let imageStyle
    let iconsPerView

    // xs : 5 icons 50px
    // sm : 5 icons 70px
    // md : 7 icons 80px
    // lg : 10 icons 80px?
    // xl : 10 icons 80px

    if (isXM){
        iconsPerView = 5
        imageStyle = {
            width: '50px',
            filter: "grayscale(50%)"
        }
    } else if (isSM) {
        iconsPerView = 5
        imageStyle = {
            width: '70px',
            filter: "grayscale(50%)"
        }
    } else if (greaterThanSM) {
        if (isMD)
            iconsPerView = 7
        else 
            iconsPerView = 10
        imageStyle = {
            width: '80px',
            filter: "grayscale(50%)"
        }
    }

    return (
    <>
        <Hero />
        
        <Box bgcolor="primary.main" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py:4 }}>
            <Typography color="inherit" align='center' variant="h2" sx={{ maxWidth: { xs: '90%', sm: '100%' } }}>
                Diseño y Desarrollo a Medida
            </Typography>
            <Typography color="inherit" align='center' variant="body1" pt={1} sx={{ maxWidth: { xs: '90%', sm: '70%' } }}>
                Utilizamos un enfoque centrado en el usuario para diseñar interfaces intuitivas y experiencias de usuario atractivas
            </Typography>
        </Box>

        <Box bgcolor="common.black" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py:4 }} >
            <Typography color="inherit" align='center' variant="h2" sx={{ maxWidth: { xs: '90%', sm: '100%' } }}>
                Desarrollo de Aplicaciones Móviles y Web
            </Typography>
            <Typography color="inherit" align='center' variant="body1" pt={1} sx={{ maxWidth: { xs: '90%', sm: '70%', md: '60%', xl: '50%' } }}>
                Nos especializamos en la creación de aplicaciones móviles y sitios web atractivos y compatibles con una amplia variedad de dispositivos y plataformas
            </Typography>
        </Box>

        <Box bgcolor="primary.main" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py:4 }}>
            <Typography color="inherit" align='center' variant="h2" sx={{ maxWidth: { xs: '90%', sm: '100%' } }}>
                Mantenimiento y Actualización de Sistemas
            </Typography>
            <Typography color="inherit" align='center' variant="body1" pt={1} sx={{ maxWidth: { xs: '90%', sm: '70%', md: '60%', xl: '50%' } }}>
                Brindamos tranquilidad a nuestros clientes al garantizar que sus aplicaciones y sistemas existentes funcionen de manera óptima y estén al día con las últimas tecnologías
            </Typography>
        </Box>

        <Box bgcolor="common.black" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py:4 }}>
            <Typography color="inherit" align='center' variant="h2" sx={{ maxWidth: { xs: '90%', sm: '100%' } }}>
                Algunas tecnologías con las que trabajamos
            </Typography>
            <Typography color="inherit" align='center' variant="body1" pt={4} sx={{ maxWidth: { xs: '90%', sm: '70%' } }}>
                <swiper-container slides-per-view={iconsPerView} speed="1000" loop="true" autoplay-delay="700" autoplay-disable-on-interaction="false" >
                    {
                        // eslint-disable-next-line
                        icons.map((icon, index) => {
                            if (index % 2 === 0)
                                return (
                                    <swiper-slide key={icon.alt}>
                                        <img alt={icon.alt} src={icon.image} style={imageStyle} />
                                    </swiper-slide>
                                )
                        })
                    }
                </swiper-container>
                <swiper-container slides-per-view={iconsPerView} speed="1000" loop="true" autoplay-delay="700" autoplay-disable-on-interaction="false" dir="rtl" >
                    {
                        // eslint-disable-next-line
                        icons.map((icon, index) => {
                            if (index % 2 !== 0)
                                return (
                                    <swiper-slide key={icon.alt}>
                                        <img alt={icon.alt} src={icon.image} width="50px" style={imageStyle} />
                                    </swiper-slide>
                                )
                        })
                    }
                </swiper-container>
            </Typography>
        </Box>
    </>
    )
}

export default Home