import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'

import kinTheme from './config/theme'
import UI from './components/UI/UI'
import Home from './pages/Home'
// import About from './pages/About'
import Portfolio from './pages/Portfolio'
// import Contact from './pages/Contact'

function App() {
	let theme = createTheme(kinTheme)
	theme = responsiveFontSizes(theme)

	return (
		<ThemeProvider theme={theme}>
			<UI>
				<Routes>
					<Route path="*" element={ <h1>Error 404</h1> } />

					<Route path="/" element={ <Home /> } />
					{/* <Route path="/sobre-nosotros" element={ <About /> } /> */}
					<Route path="/portafolio" element={ <Portfolio /> } />
					{/* <Route path="/contactanos" element={ <Contact /> } /> */}
				</Routes>
			</UI>
		</ThemeProvider>
	)
}

export default App