import React from 'react'

import PropTypes from 'prop-types'
import { Box, CssBaseline, Container, Toolbar } from '@mui/material'

import Footer from './Footer'
import Header from './Header'

const UI = (props) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
            <CssBaseline />
            <Header />

            <Toolbar />
            
            <Container component="main" maxWidth="100%" style={{ padding: 0 }}>
                { props.children }
            </Container>

            <Footer />
        </Box>
    )
}

UI.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
}

export default UI