import React from 'react'
import '../components/pages/Portfolio/styles.css'
import proyects from '../components/pages/Portfolio/proyects.js'

import { Card, CardActions, CardActionArea, CardContent, CardMedia, Divider, Typography, IconButton, Stack } from '@mui/material'
import { Android, Apple, Download } from '@mui/icons-material';

const Portfolio = () => {
    return (
        <div className='container'>
            {
                proyects.map((proyect) => 
                    <section key={proyect.name} className={proyect.class}>
                        <Card sx={{ maxWidth: 250, backgroundColor: 'transparent'}}>
                            <CardActionArea onClick={() => { window.open(proyect.web) }}>
                                <CardMedia component="img"  height="250" image={proyect.icon} alt={ proyect.icon } />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                        {proyect.name}
                                    </Typography>
                                    <Typography variant="body">
                                        {proyect.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Stack direction="row" spacing={2}>
                                    { proyect.android && 
                                        <IconButton size="large" onClick={ () => window.open(proyect.android) }>
                                            <Android />
                                        </IconButton>
                                    }
                                    { proyect.ios &&
                                        <IconButton size="large" onClick={ () => window.open(proyect.ios) }>
                                            <Apple />
                                        </IconButton>
                                    }
                                    <Divider orientation="vertical" flexItem />
                                    <IconButton size="small" >
                                        {proyect.downloads}
                                        <Download />
                                    </IconButton>
                                </Stack>
                                

                            </CardActions>
                        </Card>
                    </section>
                )
            }
            
        </div>
    )
}

export default Portfolio