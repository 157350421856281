import gifxchangeLogo from '../../../assets/img/logo-giftxchange.png'

const proyects = [
    {
        class: 'giftxchange',
        name: 'Intercambio de regalos',
        description: 'Realiza intercambios con tus amigos o colegas del trabajo.',
        downloads: '+200K',
        icon: gifxchangeLogo,
        web: 'https://giftxchange.app',
        android: 'https://play.google.com/store/apps/details?id=com.kinsolutionsystems.intercambionavidenio',
        ios: 'https://apps.apple.com/mx/app/intercambio-de-regalos/id1593113147?l=en-GB?l=en'
    },
    {
        class: 'santasmailbox',
        name: 'Buzón de Santa',
        description: 'Escribe tu carta a Santa y recibe lo que deseas para esta navidad.',
        downloads: '+10K',
        icon: 'https://buzondesanta.kinsolutionsystems.com/wp-content/uploads/2022/08/Buzon-Icon-011.png',
        web: 'https://buzondesanta.kinsolutionsystems.com',
        android: 'https://play.google.com/store/apps/details?id=com.kinsolutionsystems.buzonsanta',
        ios: 'https://apps.apple.com/mx/app/buz%C3%B3n-de-santa-claus/id1639643573?l=es'
    },
    {
        class: 'smartvads',
        name: 'SmarTV Ads',
        description: 'Con SmarTV Ads podrás configurar cualquier Android TV como un cartel publicitario.',
        downloads: '+100',
        icon: 'https://staging-smartvsigns.web.app/static/media/logo.bd14fd099279d5aab4e2.webp',
        web: 'https://smartvads.com/',
        android: 'https://play.google.com/store/apps/details?id=com.kinsolutionsystems.com.smartvsigns.tv',
        ios: ''
    }
]

export default proyects