import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { AppBar, Box, Divider, Drawer, IconButton, List, 
    ListItem, ListItemButton, ListItemText, Toolbar, 
    Typography, Button } from '@mui/material'
import { Menu } from '@mui/icons-material'

import navItems from '../../routes/pages'
import socials from '../../config/socials'

import logo from '../../assets/img/kin-logo.svg'

const drawerWidth = 240

const Header = (props) => {
    const { window } = props
    const [ mobileOpen, setMobileOpen ] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                KIN
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton component={Link} to={item.path} sx={{ textAlign: 'center' }} >
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Box width="100%" sx={{ position:'absolute', bottom: '0', textAlign: 'center' }} >
                <Divider />
                <Typography variant="body1" mt={2}>
                    Contacto
                </Typography>
                <List dense={true} >
                    { socials.map(item => 
                        <ListItem key={item.url}>
                            <ListItemButton component={Link} to={item.url} sx={{ textAlign: 'center' }} >
                                <ListItemText primary={item.name} />
                                {item.icon}
                            </ListItemButton>
                        </ListItem>
                    ) }
                </List>
            </Box>
        </Box>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <>
            <AppBar component="nav">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                        <img height={50} src={logo} alt='Kin Solution Systems Logo'/>
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        { navItems.map((item) => (
                            <Button key={item.label} component={Link} to={item.path} sx={{ color: '#fff' }} align="center">
                                {item.label}
                            </Button>
                        )) }
                    </Box>
                    <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerToggle} sx={{ display: { sm: 'none' } }} align='right'>
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer anchor="right" container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} 
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }} >

                    {drawer}
                </Drawer>
            </Box>
        </>
    )
}

export default Header